// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TableScss from "./Table.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TableScss;

function textSizeToClass(textSize) {
  if (textSize === "Primary") {
    return css.primary;
  } else {
    return "";
  }
}

function textAlignToClass(textAlign) {
  switch (textAlign) {
    case "Left" :
        return css.alignLeft;
    case "Center" :
        return css.alignCenter;
    case "Right" :
        return css.alignRight;
    
  }
}

function TableCell(props) {
  var __className = props.className;
  var __displayOnMobile = props.displayOnMobile;
  var __textAlign = props.textAlign;
  var __textSize = props.textSize;
  var textSize = __textSize !== undefined ? __textSize : "Normal";
  var textAlign = __textAlign !== undefined ? __textAlign : "Left";
  var displayOnMobile = __displayOnMobile !== undefined ? __displayOnMobile : true;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("td", {
              children: props.children,
              className: Cx.cx([
                    textSizeToClass(textSize),
                    textAlignToClass(textAlign),
                    displayOnMobile ? "" : css.hideOnMobile,
                    className
                  ]),
              colSpan: props.colSpan
            });
}

var make = TableCell;

export {
  css ,
  textSizeToClass ,
  textAlignToClass ,
  make ,
}
/* css Not a pure module */
