// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function textSizeToClass(textSize) {
  switch (textSize) {
    case "XS" :
        return "text-xs";
    case "SM" :
        return "text-sm";
    case "Base" :
        return "text-base";
    case "LG" :
        return "text-lg";
    
  }
}

function Table(props) {
  var __className = props.className;
  var __textSize = props.textSize;
  var textSize = __textSize !== undefined ? __textSize : "SM";
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("table", {
              children: props.children,
              className: Cx.cx([
                    textSizeToClass(textSize),
                    className
                  ])
            });
}

var make = Table;

export {
  textSizeToClass ,
  make ,
}
/* react/jsx-runtime Not a pure module */
